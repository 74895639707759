import React, { useEffect, useState } from 'react'
import StatusActive from './StatusActive';
import Thermometer from '../Charts/Thermometer';

const Temperatur = ({ onValue }) => {

    const [valueData, setValueData] = useState(0);
    const [maxValue, setMaxValue] = useState(600);
    const [data, setData] = useState([]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             if (dataSource) {
    //                 const response = dataSource.filter((item) => item.SensorCode === 'TMP001');
    //                 const convertValue = parseFloat(response.map((item) => item.Value1));
    //                 setValueData(convertValue);
    //                 setData(response);
    //                 if (convertValue > maxValue) {
    //                     setMaxValue(Math.ceil(convertValue / 10) * 10);
    //                 }
    //             }
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };

    //     fetchData();

    // }, [dataSource, maxValue]);

    useEffect(() => {
        // const convertValue = 48;
        setValueData(onValue);
        setMaxValue(Math.ceil(onValue / 10) * 10)
    }, [onValue]);


    return (
        // <div className="relative bg-white flex flex-col justify-center items-center w-full p-4 rounded-lg gap-2">
        <div>
            {/* <StatusActive setData={data} /> */}
            <div className="relative flex flex-row-reverse">

                <h2 className="absolute -right-10 xl:-right-16 top-50 font-semibold text-xl xl:text-3xl">{valueData ? valueData : 0}&deg;C</h2>

                <Thermometer
                    theme="light"
                    value={valueData}
                    max={maxValue}
                    format="°C"
                    size="normal"
                    width={window.innerWidth > 768 ? 10 : 8}
                    height={window.innerWidth > 768 ? 200 : 190}
                    steps="4"
                />

            </div>
        </div>
    )
}

export default Temperatur