import React from 'react'
import Chart from 'react-google-charts'

const Speedometer = ({ onValue, onName }) => {

    const gaugeData = [
        ['Label', 'Value'],
        [onName ? onName : '', onValue ? onValue : 0],
    ];

    return (
        <Chart
            width={window.innerWidth > 768 ? 200 : 175}
            height={window.innerWidth > 768 ? 200 : 175}
            chartType="Gauge"
            data={gaugeData}
            options={{
                redFrom: 1500,
                redTo: 2000,
                yellowFrom: 1000,
                yellowTo: 1500,
                minorTicks: 5,
                max: 2000,
            }}
        />
    );
}

export default Speedometer;