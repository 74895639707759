import React from 'react'

const DashboardAdmin = () => {

    const data = JSON.parse(localStorage.getItem('Data'));

    console.log(data);


    return (
        <div>Welcome back {data.UserName}</div>
    )
}

export default DashboardAdmin