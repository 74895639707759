import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Layout, Menu, message, theme } from 'antd';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PieChartOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Cookies from "js-cookie";
import axios from 'axios';

const { Header, Content, Footer, Sider } = Layout;

const MySidebar = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation(); // Menggunakan useLocation untuk mendapatkan lokasi saat ini
    const [collapsed, setCollapsed] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setMobile(window.innerWidth <= 768);
        };
        if (mobile) {
            setCollapsed(true);
        } else {
            setCollapsed(false);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [mobile]);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    function getItem(label, key, icon, href, children) {
        return {
            key,
            icon,
            label: href ? <Link to={href}>{label}</Link> : label,
            children,
        };
    }

    const handleMenuClick = ({ key }) => {
        if (mobile) {
            setCollapsed(true);
        }
    };

    const dataSession = JSON.parse(localStorage.getItem('Data'));

    const items = [
        getItem("Dashboard", "1", <PieChartOutlined />, "dashboard"),
        getItem("Account", "2", <UserOutlined />, dataSession.AccountId === "SINERGI" ? "account" : `account/edit/${dataSession.AccountId}`),
    ];

    const handleLogout = async () => {
        try {
            const token = Cookies.get('accessToken');
            const res = await axios.get(`https://iot.sinergiteknologi.co.id/api/user/logout`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            message.success(res.data.ResponseMessage);
            localStorage.clear();
            Cookies.remove('accessToken');
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    };

    const menuItems = [
        {
            key: 'account',
            icon: <UserOutlined />,
            label: <Link to={`account/edit/${dataSession.AccountId}`}>Profile Account</Link>,
        },
        {
            type: 'divider',
        },
        {
            key: 'logout',
            icon: <LogoutOutlined />,
            label: <button onClick={handleLogout}>Sign Out</button>,
        },
    ];

    const currentPath = location.pathname;

    return (
        <Layout style={{ minHeight: "100vh" }}>

            {!mobile || (mobile && !collapsed) ? (
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    width={250} // Menambahkan lebar Sider
                    style={{
                        overflow: "auto",
                        height: "100vh",
                        position: "fixed",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        background: colorBgContainer,
                    }}
                >
                    <div className="demo-logo-vertical" />
                    <div className="w-full px-4 py-2 flex items-center justify-center">
                        {!collapsed ? (
                            <p className="text-2xl font-bold text-indigo-700">IOT SENSOR</p>
                            // <img src="/assets/images/logo-text.png" alt="..." className="w-full" />
                        ) : (
                            <p className="text-2xl font-bold text-indigo-700">IOT</p>
                            // <img src="/assets/images/logo.png" alt="..." className="w-12" />
                        )}
                    </div>
                    <Menu
                        theme="light"
                        mode="inline"
                        defaultSelectedKeys={[items.find(item => currentPath.includes(item.label.props.to))?.key || "1"]}
                        items={items}
                        onClick={handleMenuClick}
                        selectedKeys={[items.find(item => currentPath.includes(item.label.props.to))?.key || "1"]} // Menyeting menu yang aktif
                    />
                </Sider>
            ) : null}

            <Layout
                style={{
                    marginLeft: mobile && collapsed ? 0 : (collapsed ? 80 : 250),
                    transition: "margin-left 0.2s",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: "16px",
                            width: 64,
                            height: 64,
                            marginLeft: mobile ? "8px" : 0,
                        }}
                    />
                    {mobile && !collapsed ?
                        ""
                        : (
                            <Dropdown menu={{ items: menuItems }} trigger={['click']}>
                                <div className="flex items-center justify-center gap-2 mr-5 cursor-pointer">
                                    <div className="border rounded-full p-2 flex items-center justify-center">
                                        <UserOutlined style={{ fontSize: '16px' }} />
                                    </div>
                                    <p>{dataSession.UserName}</p>
                                </div>
                            </Dropdown>
                        )}
                </Header>
                <Content
                    style={{
                        margin: "24px 16px 0",
                        overflow: "initial",
                        flex: 1,
                    }}
                >
                    {children}
                </Content>
                <Footer
                    style={{
                        textAlign: "center",
                    }}
                >
                    Ant Design ©{new Date().getFullYear()} Created by Ant UED
                </Footer>
            </Layout>
        </Layout>
    );
};
export default MySidebar;
