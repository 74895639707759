import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Popconfirm, Table, Typography, Button, message } from 'antd';
import { CloseOutlined, DeleteOutlined, EditFilled, SaveFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getUser } from '../API/GetData';
const { Title } = Typography;

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    Vendor,
    ...restProps
}) => {

    return (
        <td {...restProps}>
            {editing ? (
                <div>
                    {dataIndex === 'Email' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                                {
                                    type: "email",
                                    message: "The input is not a valid email address!",
                                },
                            ]}
                        >
                            <Input placeholder={title} />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ]}
                        >
                            {dataIndex === 'UserPwd' ? (
                                <Input.Password placeholder={title} />
                            ) : (
                                <Input placeholder={title} />
                            )}
                        </Form.Item>
                    )}
                </div>
            ) : (
                dataIndex === 'UserPwd' ? (
                    <span>**************</span> // Mengganti tampilan password dengan bintang
                ) : (
                    children
                )
            )}
        </td>
    );
};



const Formuser = ({ onSaveData, onApproval, onAccountId, onEdit }) => {

    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    const [editingKey, setEditingKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    // const [dataUser, setDataUser] = useState([]);

    useEffect(() => {
        if (onEdit) {
            const fetchUser = async () => {
                try {
                    const response = await getUser();
                    const filter = response.filter((item) => item.AccountId === onAccountId).map((row, index) => ({ ...row, key: index + 1 })).reverse();
                    setData(filter);
                    setCount(filter.length === 0 ? 0 : filter.map((item) => item.key)[0])
                    onSaveData(filter)
                } catch (error) {
                    console.log();
                    setData([]);
                    setCount(0);
                    onSaveData([]);
                }
            };
            fetchUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // console.log("Data user", data);

    const isEditing = (record) => record.key === editingKey;

    const handleEdit = (record) => {

        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.key);
    };

    const handleDelete = (key) => {
        setIsDisable(false);
        const deletedRow = data.find((row) => row.key === key);
        const deletedNumber = deletedRow.key;
        const deletedkey = deletedRow.key;

        const newData = data.filter((row) => row.key !== key);

        const updatedData = newData.map((row) => {

            if (row.key > deletedNumber && row.key > deletedkey) {
                return { ...row, key: row.key - 1 };
            }
            return row;
        });

        setCount(updatedData.length > 0 ? updatedData[0].key : 0);

        setData(updatedData);
        // onSaveData(updatedData);

        // console.log("DataFormTran", updatedData);
    };


    const handleCancel = (record) => {
        if (!record.email) {
            const newData = data.filter((item) => item.key !== record.key);
            setData(newData);
        } else {
            setEditingKey('');
        }
        setEditingKey('');

        // console.log("DataFormTran", data);
    };


    const handleSave = async (record) => {
        setIsDisable(false);
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => record.key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                // onSaveData(newData)
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                // onSaveData(newData)
            }

            const editedRow = data.find((row) => row.key === record.key);
            const lastNumber = editedRow.key;

            setCount(lastNumber);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const handleAdd = () => {

        const num = count + 1;

        // if (editingKey) {
        //     enqueueSnackbar("Complete the input form !", { variant: "warning" });
        //     return; // Stop saving if duplicate found
        // }

        const newData = {
            key: num,
            AccountId: onAccountId,
            RefAccountId: `${onAccountId}${num}`,
            UserName: "",
            Email: "",
            UserPwd: ""
        };
        setData([newData, ...data]);
        handleEdit(newData);

        // console.log("DataFormTran", data);
    };

    const handleSaveAllData = async () => {
        setLoading(true);
        setIsDisable(true);
        try {
            onSaveData(data);
            console.log("PostData", data);
            message.success('Success add form table data!!');
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleCancelAllData = () => {
        setData(data);
        setCount(0);
        // onSaveData([]);
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 60,
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Username',
            dataIndex: 'UserName',
            width: 150,
            editable: true,
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            width: 200,
            editable: true,
        },
        {
            title: 'Password',
            dataIndex: 'UserPwd',
            width: 150,
            editable: true,
        },
    ];

    if (!onApproval) {
        columns.push({
            title: 'Actions',
            dataIndex: 'actions',
            fixed: "right",
            width: 80,
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleSave(record)} style={{ fontSize: '18px' }}>
                            <SaveFilled />
                        </Typography.Link>

                        <Typography.Link onClick={() => handleCancel(record)} style={{ fontSize: '18px' }}>
                            <CloseOutlined />
                        </Typography.Link>
                    </span>
                ) : (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleEdit(record)} style={{ fontSize: '18px' }}>
                            <EditFilled />
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                            <Link style={{ fontSize: '18px', color: "red" }}>
                                <DeleteOutlined />
                            </Link>
                        </Popconfirm>
                    </span>
                );
            },
        },)
    }

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
            ...col,
        };
    });

    return (
        <Form form={form} component={false}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "0 10px 10px"
                }}
            >
                <Title level={3} style={{ margin: "0" }}>
                    Users
                </Title>
                {!onApproval && (
                    <Button
                        onClick={handleAdd}
                        type="primary"
                        disabled={!!editingKey || !onAccountId}
                    >
                        + Add Data
                    </Button>
                )}
            </div>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                // bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: handleCancel,
                    pageSize: 5,
                }}
                scroll={{
                    x: 500,
                }}
            />
            {!onApproval && (
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                        margin: "10px 10px 0"
                    }}
                >
                    <Popconfirm title="Sure to clear all data?" onConfirm={handleCancelAllData}
                    >
                        <Button
                            disabled={!!editingKey || !!isDisable}
                        >
                            <span>Cancel</span>
                        </Button>
                    </Popconfirm>
                    <Button
                        type="primary"
                        onClick={handleSaveAllData}
                        loading={loading}
                        disabled={!!editingKey || !!isDisable}
                    >
                        <span>Save</span>
                    </Button>

                </div>
            )}
        </Form>
    );
};
export default Formuser;