import axios from "axios";

const baseURL = process.env.REACT_APP_BASEURL;

export const getCurrent = async (AccountId, ObjectCode) => {
    const response = await axios.get(`${baseURL}/dashboard/get/current?AccountId=${AccountId}&ObjectCode=${ObjectCode}`);
    return response.data;
}

export const getHisReading = async () => {
    const response = await axios.get(`${baseURL}/dashboard/get/his_reading?AccountId=SGM&ObjectCode=G2008&SensorCode=&StartDate=2024-07-18 02:20:00&EndDate=2024-08-23 23:30:00&Time=10`)
    return response.data;
}

export const getTitleLogo = async (AccountId) => {
    const response = await axios.get(`${baseURL}/dashboard/get/title_logo?AccountId=${AccountId}`);
    return response.data;
}