import React from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Cell } from 'recharts'

const BarCharts = ({ onValue }) => {
    // Buat data array untuk BarChart dari JSON
    const dataSource = [
        {
            name: onValue.Flag2,
            value: onValue.Value2,
            fill: '#47D359',  // Warna untuk Value2
        },
        {
            name: onValue.Flag3,
            value: onValue.Value3,
            fill: '#4D93D9',  // Warna untuk Value3
        },
        {
            name: onValue.Flag4,
            value: onValue.Value4,
            fill: '#B80000',  // Warna untuk Value4
        },
    ];

    return (
        <div className="w-full h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={dataSource}
                    margin={{
                        top: 40,
                        right: 10,
                        left: 0,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Bar dataKey="value" label={{ position: 'top', color: "black" }}>
                        {dataSource.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default BarCharts
