import axios from "axios";
import Cookies from "js-cookie";

const baseURL = "https://iot.sinergiteknologi.co.id/api";

export const getAccount = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseURL}/get/account`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getUser = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseURL}/get/user`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
} 