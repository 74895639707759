import React, { useEffect, useState } from 'react'
import HeaderTitle from '../components/Global/HeaderTitle'
import { Link } from 'react-router-dom'
import { Button, Image, Input, Table } from 'antd'
import { getAccount } from '../API/GetData'
import Users from '../User'
import { EditFilled } from '@ant-design/icons'

const AccountAdmin = () => {

    const [dataSource, setDataSource] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await getAccount();
            setDataSource(response);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filteredData = dataSource.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const columns = [
        {
            title: "No",
            dataIndex: "key",
            key: "key",
            fixed: "left",
            width: 80,
        },
        {
            title: "Icon",
            dataIndex: "IconName",
            key: "IconName",
            width: 100,
            render: (_, record) => {
                return (
                    <Image
                        src={`${process.env.REACT_APP_BASEURL_IMG}/${record.IconName}`}
                        alt={record.IconName}
                        onError={(e) => e.target.src = '/assets/images/img-notfound.png'}
                        width={80}
                        height={80}
                    />
                )
            }
        },
        {
            title: "Account Id",
            dataIndex: "AccountId",
            key: "AccountId",
            width: 120,
        },
        {
            title: "Account Name",
            dataIndex: "AccountName",
            key: "AccountName",
            width: 200,
        },
        {
            title: "Alamat",
            dataIndex: "Alamat",
            key: "Alamat",
            width: 250,
        },
        {
            title: "Kota",
            dataIndex: "Kota",
            key: "Kota",
            width: 120,
        },
        {
            title: "Provinsi",
            dataIndex: "Provinsi",
            key: "Provinsi",
            width: 120,
        },
        {
            title: "Telephone",
            dataIndex: "Telp",
            key: "Telp",
            width: 120,
        },
        {
            title: "Fax",
            dataIndex: "Fax",
            key: "Fax",
            width: 120,
        },
        {
            title: "Nama PKP",
            dataIndex: "NamaPKP",
            key: "NamaPKP",
            width: 150,
        },
        {
            title: "Alamat PKP",
            dataIndex: "AlamatPKP",
            key: "AlamatPKP",
            width: 250,
        },
        {
            title: "NPWP",
            dataIndex: "NPWP",
            key: "NPWP",
            width: 120,
        },
        {
            title: "Email",
            dataIndex: "Email",
            key: "Email",
            width: 250,
        },
        {
            title: "Website",
            dataIndex: "Website",
            key: "Website",
            width: 250,
        },
    ]

    return (
        <div>
            <div className="flex justify-between items-center px-2 pb-4">
                <HeaderTitle
                    title="ACCOUNT"
                    subtitle="All data Account"
                />
                <div>
                    <Link to="/admin/account/form">
                        <Button type="primary">+ Add New</Button>
                    </Link>
                </div>
            </div>
            <div className="w-full bg-white p-4 rounded-lg">
                <div className="w-full flex justify-end pb-4">
                    <Input
                        placeholder="search..."
                        allowClear
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ width: 200 }}
                    />
                </div>
                <Table
                    loading={loading}
                    // rowSelection
                    columns={columns}
                    dataSource={filteredData}
                    expandable={{
                        expandedRowRender: (record) => {
                            return (
                                <>
                                    {record.Description ?
                                        (
                                            <div className="flex flex-col gap-2 p-2 border-b-2 mb-2">
                                                <p className="text-lg font-medium">Description :</p>
                                                <p className="text-md">{record.Description}</p>
                                            </div>

                                        ) : ''}
                                    <Users onAccountId={record.AccountId} />
                                </>
                            )
                        }
                    }}
                    pagination={{
                        showSizeChanger: true,
                        defaultPageSize: 10,
                    }}
                    scroll={{
                        x: 1500,
                    }}
                />
            </div>
        </div>
    )
}

export default AccountAdmin