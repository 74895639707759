import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getHisReading } from '../../API/GET_DATA';
import FilterDate from '../../components/FilterDate';

const History = () => {
    const [dataSource, setDataSource] = useState([]);

    const fetchData = async () => {
        try {
            const response = await getHisReading();
            setDataSource(response);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const groupedData = dataSource && Array.isArray(dataSource)
        ? dataSource.reduce((acc, item) => {
            if (!acc[item.Group]) {
                acc[item.Group] = {};
            }
            if (!acc[item.Group][item.Flag]) {
                acc[item.Group][item.Flag] = [];
            }
            acc[item.Group][item.Flag].push(item);
            return acc;
        }, {})
        : {};

    // console.log("Group Data", groupedData);

    return (
        <div className="flex flex-col gap-4 p-4">
            <FilterDate />
            <div className="flex flex-col gap-4">
                {Object.keys(groupedData).map((key) => (
                    <div key={key} className="bg-white p-4 rounded-md">
                        <h3 className="text-2xl mb-4 font-bold">{key}</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {Object.keys(groupedData[key]).map((flag) => (
                                <div key={flag}>
                                    <h4 className="text-center mb-2 text-xl">{flag}</h4>
                                    <ResponsiveContainer width="100%" height={250}>
                                        <LineChart
                                            data={groupedData[key][flag]}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="refDate" />
                                            <YAxis />
                                            <Tooltip />
                                            <Line type="monotone" dataKey="SensorValue" stroke="#8884d8" activeDot={{ r: 8 }} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default History;
