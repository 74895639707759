import React, { useEffect, useState } from 'react'
import HeaderTitle from '../components/Global/HeaderTitle'
import { Input, Table } from 'antd'
import { getUser } from '../API/GetData'

const Users = ({ onAccountId }) => {

    const [dataSource, setDataSource] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await getUser();
            const filter = response.filter((item => item.AccountId === onAccountId));
            setDataSource(filter.map((row, index) => ({ ...row, key: index + 1 })));
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredData = dataSource.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const columns = [
        {
            title: "No",
            dataIndex: "key",
            key: "key"
        },
        {
            title: "AccountId",
            dataIndex: "AccountId",
            key: "AccountId"
        },
        {
            title: "Username",
            dataIndex: "UserName",
            key: "UserName"
        },
        {
            title: "Email",
            dataIndex: "Email",
            key: "Email"
        },
        // {
        //     title: "User Password",
        //     dataIndex: "UserPwd",
        //     key: "UserPwd",

        // },
    ]

    return (
        <div>
            <div className="w-full bg-white p-4 rounded-lg">
                <div className="w-full flex justify-between pb-4">
                    <HeaderTitle
                        title="USERS"
                    // subtitle="All data Users"
                    />
                    <Input
                        placeholder="search..."
                        allowClear
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ width: 200 }}
                    />
                </div>
                <Table
                    loading={loading}
                    // rowSelection
                    columns={columns}
                    dataSource={filteredData}
                    pagination={{
                        showSizeChanger: true,
                        defaultPageSize: 5,
                    }}
                />
            </div>
        </div>
    )
}

export default Users