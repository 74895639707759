import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";

const baseURL = "https://iot.sinergiteknologi.co.id/api"

const LoginAdmin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const fetchLogin = async (event) => {
        event.preventDefault();
        console.log(email);

        try {
            setIsLoading(true);
            const response = await axios.post(`${baseURL}/user/login`, {
                Email: email,
                UserPwd: password
            });
            console.log(response.data);
            Cookies.set('accessToken', response.data.Token, { expires: 1 });
            localStorage.setItem('Data', JSON.stringify(response.data));
            // localStorage.setItem('AccountId', response.data.AccountId);
            // localStorage.setItem('refAcount', response.data.RefAccountId);
            // localStorage.setItem('UserName', response.data.UserName);
            navigate('dashboard');
        } catch (error) {
            console.log(error);
            setError("Email dan Password tidak sesuai!")
        }
        setIsLoading(false);
    }

    return (
        <div className="bg-gray-100 px-4">
            <div className="flex min-h-screen items-center justify-center">
                <div className="bg-white w-[450px] rounded-lg p-4 shadow-md">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img
                            alt="Your Company"
                            src="/assets/images/venus-logo.png"
                            className="mx-auto h-32 w-auto"
                        />
                        <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            Sign in <br /> admin account
                        </h2>
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form onSubmit={fetchLogin} className="space-y-6">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        required
                                        autoComplete="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="Email Address"
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                        Password
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        required
                                        autoComplete="current-password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="********"
                                    />
                                </div>
                            </div>

                            {error && (
                                <div className="text-red-500 text-sm mt-2">
                                    {error}
                                </div>
                            )}

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 p-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    disabled={isLoading}
                                >
                                    {!isLoading ? (
                                        "Sign in"
                                    ) : (
                                        "Loading..."
                                    )}
                                </button>
                            </div>
                        </form>

                        <p className="mt-10 text-center text-sm text-gray-500">
                            more product! our website{' '}
                            <Link to="https://sinergiteknologi.co.id" target='_blank' className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                                PT. Sinergi Teknologi Solusindo
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginAdmin;
