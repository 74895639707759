import React from 'react'
import FilterDate from '../../components/FilterDate'

const Efficiency = () => {
  return (
    <div className="flex flex-col gap-4 p-4">
      <FilterDate />
    </div>
  )
}

export default Efficiency