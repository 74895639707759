import axios from "axios";
import Cookies from "js-cookie";

const baseURL = "https://iot.sinergiteknologi.co.id/api";

export const postAccount = async (AccountData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseURL}/post/account`, AccountData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
}

export const postUser = async (UserData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseURL}/post/users`, UserData, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response;
} 