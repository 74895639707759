import React, { useEffect, useState } from 'react'
import Temperatur from '../../components/Temperatur'
import Speedometer from '../../components/Speedometer'
import WaterLevel from '../../components/WaterLevel'
import { getCurrent } from '../../API/GET_DATA'
import StatusActive from '../../components/StatusActive'
import BarCharts from '../../components/BarCharts'
import { Spin } from 'antd'; // Assuming you're using Ant Design for a spinner

const Sensor = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    const fetchData = async () => {
        try {
            const response = await getCurrent('WMA', 'B0V1EFKGZ0RKQ276');
            setData(response);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false); // Stop loading after data is fetched
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const renderSensor = (sensor, value, name) => {
        switch (sensor) {
            case 'Temperatur':
                return <Temperatur onValue={value} />;
            case 'RPM':
                return <Speedometer onValue={value} onName={name} />;
            case 'WLevel':
                return <WaterLevel onValue={value} />;
            case 'Bar':
                return <BarCharts onValue={value} />;
            default:
                return <Speedometer onValue={value} onName={name} />;
        }
    }

    const groupedData = data.CurrentA && Array.isArray(data.CurrentA)
        ? data.CurrentA.reduce((acc, data) => {
            if (!acc[data.Group]) {
                acc[data.Group] = [];
            }
            acc[data.Group].push(data);
            return acc;
        }, {})
        : {};

    const lastUpdateA = data.CurrentE && Array.isArray(data.CurrentE) && data.CurrentE.map((item, index) => (item))

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Spin size="large" /> {/* Loading spinner */}
            </div>
        );
    }

    return (
        <div className="grid md:grid-cols-4 gap-4 p-4">
            {Object.keys(groupedData).map((Group, index) => (
                <div key={index} className="flex flex-col col-span-4 md:p-0 p-4">
                    <h2 className="text-2xl font-bold mb-4">{Group}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        {groupedData[Group].map((data, idx) => (
                            <div key={idx} className="relative p-4 pt-4 bg-white shadow-md rounded-md col-span-1 md:col-span-1">
                                <div className={`flex items-center justify-between border-b-4 pb-1`}>
                                    <h2 className="text-xl w-full">{data.Flag}</h2>
                                    <StatusActive setData={data} />
                                </div>
                                <div className="w-full flex items-center justify-center">
                                    {renderSensor(data.Sensor, parseFloat(data.SensorValue), '')}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}

            <div className="col-span-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {data.CurrentE && Array.isArray(data.CurrentE) && data.CurrentE.map((item, index) => (
                        <div key={index} className="relative w-full bg-white p-4 shadow-md rounded-md">
                            <div className={`flex items-center justify-between border-b-4 pb-1`}>
                                <h2 className="text-2xl font-bold w-full">{item.Sensor}</h2>
                                <StatusActive setData={item} />
                            </div>
                            <div className="flex flex-col md:flex-row items-center justify-center">
                                <div className="flex items-center justify-center w-2/3">
                                    {renderSensor('', parseFloat(item.Value1), '')}
                                </div>
                                {renderSensor('Bar', item, '')}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="col-span-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {data.CurrentB && Array.isArray(data.CurrentB) && data.CurrentB.map((item, index) => (
                        <div key={index} className="relative p-4 pt-4 bg-white shadow-md rounded-md">
                            <div className={`flex items-center justify-between border-b-4 pb-1`}>
                                <h2 className="text-2xl font-bold w-full">{item.Sensor}</h2>
                                <StatusActive setData={item} />
                            </div>
                            <div className="flex w-full">
                                <div className="border-r-2 w-full flex flex-col items-center justify-center">
                                    <p className="text-left w-full text-xl my-2">{item.Flag1}</p>
                                    {renderSensor('RPM', parseFloat(item.Value1), '')}
                                </div>
                                <div className="grid grid-cols-1 w-full">
                                    <div className="w-full">
                                        <h2 className="text-md md:text-2xl pl-2 pt-1">{item.Flag2}</h2>
                                        <p className="text-center pt-2 text-3xl">{item.Value2}</p>
                                    </div>
                                    <div className="w-full border-t-2">
                                        <h2 className="text-md md:text-2xl pl-2 pt-1">{item.Flag3}</h2>
                                        <p className="text-center pt-2 text-3xl">{item.Value3}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="col-span-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {data.CurrentC && Array.isArray(data.CurrentC) && data.CurrentC.map((item, index) => (
                        <div key={index} className="relative p-4 pt-4 bg-white shadow-md rounded-md">
                            <div className={`flex items-center justify-between border-b-4 pb-1`}>
                                <h2 className="text-xl font-bold w-full">{item.Sensor}</h2>
                                <StatusActive setData={item} />
                            </div>
                            <div className="flex w-full">
                                <div className="border-r-2 w-full flex flex-col items-center justify-center">
                                    <p className="text-left w-full text-2xl my-2">{item.Flag1}</p>
                                    {renderSensor('RPM', parseFloat(item.Value1), '')}
                                </div>
                                <div className="grid grid-cols-1 w-full">
                                    <div className="w-full">
                                        <h2 className="text-md md:text-2xl pl-2 pt-1">{item.Flag2}</h2>
                                        <p className="text-center py-1 text-3xl">{item.Value2}</p>
                                    </div>
                                    <div className="w-full border-t-2">
                                        <h2 className="text-md md:text-2xl pl-2 pt-1">{item.Flag3}</h2>
                                        <p className="text-center py-1 text-3xl">{item.Value3}</p>
                                    </div>
                                    <div className="w-full border-t-2">
                                        <h2 className="text-md md:text-2xl pl-2 pt-1">{item.Flag4}</h2>
                                        <p className="text-center py-1 text-3xl">{item.Value4}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="col-span-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {data.CurrentD && Array.isArray(data.CurrentD) && data.CurrentD.map((item, index) => (
                        <div key={index} className="relative p-4 pt-4 bg-white shadow-md rounded-md">
                            <div className={`flex items-center justify-between border-b-4 pb-1`}>
                                <h2 className="text-2xl font-bold w-full">{item.Sensor}</h2>
                                <StatusActive setData={item} />
                            </div>
                            <div className="flex w-full">
                                <div className="border-r-2 w-full flex flex-col items-center justify-around">
                                    <p className="text-left w-full text-xl my-2">{item.Flag1}</p>
                                    {renderSensor('RPM', parseFloat(item.Value1), '')}
                                </div>
                                <div className="grid grid-cols-1 w-full">
                                    <div className="w-full">
                                        <h2 className="text-md md:text-2xl pl-2 pt-1">{item.Flag2}</h2>
                                        <p className="text-center py-1 text-3xl">{item.Value2}</p>
                                    </div>
                                    <div className="w-full border-t-2">
                                        <h2 className="text-md md:text-2xl pl-2 pt-1">{item.Flag3}</h2>
                                        <p className="text-center py-1 text-3xl">{item.Value3}</p>
                                    </div>
                                    <div className="w-full border-t-2">
                                        <h2 className="text-md md:text-2xl pl-2 pt-1">{item.Flag4}</h2>
                                        <p className="text-center py-1 text-3xl">{item.Value4}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Sensor
