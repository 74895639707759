import React, { useState } from 'react';
import { Button, Form, DatePicker, Select, Space, Alert, message } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const FilterDate = ({ accountId, sensorType, startDate, endDate, TimeRange, accountName, sensorName, startRanges, endRanges, TimesRanges }) => {

    const [showAlert, setShowAlert] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [loadingss, setLoadingss] = useState(false);

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        setLoadings(true);

        // const { RangeDate, Time, Ship, Sensor } = values;
        // if (Time === '5' || Time === '10') {
        //     const start = dayjs(RangeDate[0]);
        //     const end = dayjs(RangeDate[1]);
        //     if (!start.isSame(end, 'day')) {
        //         message.error('For Time 5 or 10, the date range must be within the same day.');
        //         setLoadings(false);
        //         return;
        //     }
        // }

        // // Simulate a 1.5-second delay
        // await new Promise((resolve) => setTimeout(resolve, 1500));

        // const formatDateTime = (isoDateString) => {
        //     const date = new Date(isoDateString);

        //     const year = date.getFullYear();
        //     const month = String(date.getMonth() + 1).padStart(2, '0');
        //     const day = String(date.getDate()).padStart(2, '0');
        //     const hours = String(date.getHours()).padStart(2, '0');
        //     const minutes = String(date.getMinutes()).padStart(2, '0');
        //     const seconds = String(date.getSeconds()).padStart(2, '0');
        //     const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        //     const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
        //     return formattedDateTime;
        // }

        // const startDateRange = RangeDate ? formatDateTime(RangeDate[0]) : startRanges;
        // const endDateRange = RangeDate ? formatDateTime(RangeDate[1]) : endRanges;

        // accountId(Ship);
        // sensorType(Sensor)
        // startDate(startDateRange);
        // endDate(endDateRange);
        // TimeRange(Time);

        // setShowAlert(true);
        setLoadings(false);
    };

    const onReset = async () => {
        setLoadingss(true);

        // Reset the form fields
        form.resetFields();

        // Simulate a 2-second delay
        // await new Promise((resolve) => setTimeout(resolve, 2000));

        // Reload the page
        window.location.reload();

        setLoadingss(false);
    };

    const messageAlert = `From ${startRanges} - ${endRanges} / ${TimesRanges} Minute, ${accountName} Ship, ${sensorName}`;

    return (
        <div className="bg-white p-4 rounded-md">
            <Space
                direction="vertical"
                style={{
                    width: '100%',
                }}
            >
                {showAlert === true ?
                    <Alert message={messageAlert} type="info" showIcon />
                    :
                    <></>
                }
                <Form
                    layout="inline"
                    onFinish={onFinish}
                    form={form}
                    initialValues={{
                        'RangeDate': [dayjs(startRanges), dayjs(endRanges)],
                        'Time': TimesRanges ? TimesRanges : "10",
                        'Sensor': sensorName ? sensorName : "All",
                        'Ship': accountName ? accountName : "WS01",
                    }}
                >
                    <div className="flex w-full flex-col justify-center gap-4">
                        <p className="text-left text-lg font-semibold px-1">Filter Date</p>
                        <div className="w-full flex flex-col md:flex-row gap-4 md:gap-2 md:items-center justify-center">

                            <Form.Item
                                name="RangeDate"
                            >
                                <RangePicker
                                    showTime={{
                                        format: 'HH:mm:ss',
                                    }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>

                            <Form.Item
                                name="Time"
                            >
                                <Select
                                    options={[
                                        {
                                            value: '5',
                                            label: '5 minute',
                                        },
                                        {
                                            value: '10',
                                            label: '10 minute',
                                        },
                                        {
                                            value: '15',
                                            label: '15 minute',
                                        },
                                        {
                                            value: '30',
                                            label: '30 minute',
                                        },
                                        {
                                            value: '45',
                                            label: '45 minute',
                                        },
                                        {
                                            value: '60',
                                            label: '60 minute',
                                        },
                                    ]}
                                />
                            </Form.Item>

                            <Form.Item
                                className="md:w-28"
                                name="Sensor"
                            >
                                <Select
                                // options={[
                                //     {
                                //         value: 'All',
                                //         label: 'All',
                                //     },
                                //     {
                                //         value: 'Engine1',
                                //         label: 'Engine 1',
                                //     },
                                //     {
                                //         value: 'Engine2',
                                //         label: 'Engine 2',
                                //     },
                                //     {
                                //         value: 'FuelLevel',
                                //         label: 'Fuel Level',
                                //     },
                                // ]}
                                />
                            </Form.Item>

                            <Form.Item
                                name="Ship"
                            >
                                <Select
                                // options={[
                                //     {
                                //         value: 'SGM',
                                //         label: 'SGM',
                                //     },
                                //     {
                                //         value: 'SPB',
                                //         label: 'SPB',
                                //     },
                                // ]}
                                />
                            </Form.Item>

                        </div>

                        <div className="flex w-full justify-end">
                            <Form.Item>
                                <Space>
                                    <Button htmlType="button" onClick={onReset} loading={loadingss} style={{ backgroundColor: "#FFFFFF" }}>
                                        Reset
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={loadings}>
                                        Submit
                                    </Button>
                                </Space>
                            </Form.Item>
                        </div>

                    </div>
                </Form>
            </Space>
        </div>
    )
}

export default FilterDate;
