import { Col, Form, Input, message, Row, Upload } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import HeaderTitle from "../components/Global/HeaderTitle";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { getAccount } from "../API/GetData";
import Formuser from "../User/form";
import { ButtonSubmit } from "../components/Global/Button";
import { updateAccount, updateUser } from "../API/UpdateData";

const EditFormAccountAdmin = () => {
    const [dataUsers, setDataUsers] = useState("");
    const { id } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [fileImage, setFileImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAccount();
                const filter = response.filter((item => item.AccountId === id))
                form.setFieldsValue(filter[0]);
                if (filter[0]?.IconName) {
                    setImageUrl(`${process.env.REACT_APP_BASEURL_IMG}/${filter[0].IconName}`);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleChange = ({ file }) => {
        if (file && file.originFileObj) {
            const allowedExtensions = ['png', 'jpg', 'jpeg'];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (allowedExtensions.includes(fileExtension)) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    setImageUrl(reader.result);
                };

                reader.readAsDataURL(file.originFileObj);

                setFileImage(file.originFileObj)

            } else {
                message.error('The uploaded file must be an image with the extension .png, .jpg, or .jpeg');
            }
        }
    };

    const onFinish = async (values) => {
        try {
            const payload = {
                ...values,
                icon: fileImage
            }

            // console.log(payload);
            // console.log(dataUsers);

            if (!dataUsers) {
                message.warning("Complete Data User!");
            } else {
                const accountResponse = await updateAccount(payload);
                if (accountResponse) {
                    const userResponse = await updateUser(dataUsers);

                    if (userResponse) {
                        message.success("Data successfully updated.");
                    }
                } else {
                    throw new Error("Failed to submit Account.");
                }
            }

            // navigate('/dashboard/review');
        } catch (error) {
            console.log(error);
            message.error("Failed to submit Account.");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onReset = () => {
        form.resetFields();
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    return (
        <>
            <div className="flex justify-between items-center px-2 pb-4">
                <HeaderTitle title="ACCOUNT" subtitle="form data a Account" />
            </div>

            <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
                initialValues={{
                    AccountId: "test"
                }}
            >
                <div className="relative w-full bg-white rounded-lg">
                    <div className="flex flex-col gap-4 w-full items-center justify-center pt-8">

                        <p className="text-xl font-bold">Upload Icon</p>

                        {/* <img src={`${process.env.REACT_APP_BASEURL_IMG}/${data.IconName}`} alt="icon" /> */}

                        <Form.Item>
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={handleChange}
                            >
                                {imageUrl ? (
                                    <img
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{ width: '100%' }}
                                    />
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                        </Form.Item>
                    </div>

                    <Row gutter={30} style={{ padding: "28px" }}>

                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Account Id"
                                name="AccountId"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Account Id!",
                                    },
                                ]}
                            >
                                <Input placeholder="Account Id" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Account Name"
                                name="AccountName"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Account Name!",
                                    },
                                ]}
                            >
                                <Input placeholder="Account Name" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Alamat"
                                name="Alamat"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Alamat!",
                                    },
                                ]}
                            >
                                <Input placeholder="Alamat" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Kota"
                                name="Kota"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Kota!",
                                    },
                                ]}
                            >
                                <Input placeholder="Kota" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Provinsi"
                                name="Provinsi"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Provinsi!",
                                    },
                                ]}
                            >
                                <Input placeholder="Provinsi" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="No Telephone"
                                name="Telp"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your No Telephone!",
                                    },
                                ]}
                            >
                                <Input
                                    // prefix="+62"
                                    placeholder="No Telephone"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                        // const value = event.currentTarget.value;
                                        // if (value.length === 0 && event.key === '0') {
                                        //     event.preventDefault();
                                        // }
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Fax"
                                name="Fax"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Fax!",
                                    },
                                ]}
                            >
                                <Input placeholder="Fax" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Nama PKP"
                                name="NamaPKP"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Nama PKP!",
                                    },
                                ]}
                            >
                                <Input placeholder="Nama PKP" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Alamat PKP"
                                name="AlamatPKP"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Alamat PKP!",
                                    },
                                ]}
                            >
                                <Input placeholder="Alamat PKP" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="NPWP"
                                name="NPWP"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your NPWP!",
                                    },
                                ]}
                            >
                                <Input placeholder="NPWP" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Email"
                                name="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Email!",
                                    },
                                    {
                                        type: "email",
                                        message: "The input is not a valid email address!",
                                    },
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Website"
                                name="Website"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Website!",
                                    },
                                ]}
                            >
                                <Input placeholder="Website" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24}>
                            <Form.Item
                                label="Description"
                                name="Description"
                            >
                                <Input.TextArea placeholder="Description..." rows={4} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} className="bg-white border p-4 rounded-lg">
                            <Formuser onSaveData={(values) => setDataUsers(values)} onAccountId={id} onEdit={true} />
                        </Col>

                    </Row>

                    <ButtonSubmit onReset={onReset} />
                </div>
            </Form>
        </>
    );
};

export default EditFormAccountAdmin;
